.section-box{
    position: relative;
}
.section-box .remove{
    position: absolute;
    top: -12px;
    right: -12px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid #f21b1b;
    background: #f21b1b;
    cursor: pointer;
    color: #fff;    
    padding-left: 7px;
}